<template>
  <div class="metago-logo"
       v-if="!useSimple"
       :style="{'--meta-log-height':height ? (/[A-z|%]/.test(height) ? height: height+'rem') : '3.6rem'}"
  >
    <div class="logo-wrap"  @click="$router.push('/index')">
          <img :class="teamInfo !== null && teamInfo.teamId === teamLogoTeamId ? 'metago-logo-img-bd' : 'metago-logo-img'" data-tag-id="logo-back-to-index" :src="teamInfo !== null && teamInfo.teamLogoUrl ? teamInfo.teamLogoUrl : logoUrl">
    </div>
  </div>
  <img v-else :class="teamInfo !== null && teamInfo.teamId === teamLogoTeamId ? 'metago-logo-img-bd' : 'metago-logo-img'" :src="teamInfo !== null && teamInfo.teamLogoUrl ? teamInfo.teamLogoUrl : logoUrl" >
</template>

<script>
import config from "../../config";
const logoUrl = require('../../../public/static/img/logo.png');
export default {
  name: "MetaGoLogo",
  props:['height','useSimple'],
  data(){
    return {
      logoUrl,
      teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
      teamLogoTeamId:config.teamLogoTeamId,
    }
  }
}
</script>

<style scoped>
.metago-logo{
  --meta-log-height:3.6rem;
  box-sizing: border-box;
  margin: 0 auto;
  height: var(--header-height);
  padding-top: calc(var(--header-top-part-height) - calc(var(--meta-log-height) / 2));
  padding-bottom: calc(var(--header-bottom-part-height) - calc(var(--meta-log-height) / 2));
}
.logo-wrap{
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  padding: 0.3rem 0;
  box-sizing: border-box;
  height: var(--meta-log-height);
}
/*北大演示css*/
.metago-logo-img-bd{
  width: 11.36rem;
  height: 3.2rem;
}
.metago-logo-img{
  width: 14rem;
}
</style>
