<template>
  <div style="display: flex;" >
    <contract-side-menu active-index="2"></contract-side-menu>
    <div style="width: calc(100% - 150px);height: 100vh">

      <div class="search" style="margin: 20px">

        <el-input v-model="searchData" placeholder="请输入搜索的内容" class="search-input">
          <template #prefix>
            <div style="width: 40px;line-height: 40px;height: 40px;display: block">
              <el-icon :size="20" style="vertical-align: middle">
                <search/>
              </el-icon>
            </div>
          </template>
        </el-input>
      </div>

      <el-container>
        <el-header style="text-align: left;border-bottom:1px solid #e0e0e0;">
          <label style="margin-right: 20px">操作</label>
          <el-button @click="$router.push('/contract/draft')">新建</el-button>
          <el-button type="danger" @click="deleteContract">删除选中</el-button>
          <div style="float: right;display: flex;">
            <label style="width: 100px;line-height: 40px;margin-right: 20px">过滤</label>
            <el-input style="margin-right: 20px" placeholder="请输入过滤条件"></el-input>
            <el-pagination
                layout="pager,total,prev, next"
                :total="totalCount"
                :current-page="currentIndex"
                :page-size="size"

                @current-change="currentChange"
                @size-change="sizeChange">
            </el-pagination>
          </div>
        </el-header>
        <el-main style="padding: 0;">
          <div style="width: calc(100% - 1px)">
          <el-table
              ref="multipleTable"
              :data="contractList"
              tooltip-effect="dark"
              height="calc(100vh - 140px)"

          >
            <el-table-column
                type="selection"
                min-width="5%">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                min-width="15%">
              <template #default="scope">
                <el-tag class="contract-status" effect="dark" v-if="scope.row.hasMetaApproval==0 && userInfo && userInfo.isManagerRole">未设置审批流</el-tag>
                <el-tag class="contract-status" effect="dark" type="info" v-else-if="scope.row.status==0">待发起</el-tag>
                <el-tag class="contract-status" effect="dark" type="warning" v-else-if=" scope.row.status==10">待签署审批中
                </el-tag>
                <el-tag class="contract-status" effect="dark" type="info" v-else-if=" scope.row.status==11">待生效</el-tag>
                <el-tag class="contract-status" effect="dark" type="error" v-else-if=" scope.row.status==12">待签署审批驳回
                </el-tag>
                <el-tag class="contract-status" effect="dark" type="warning" v-else-if=" scope.row.status==20">待生效审批中
                </el-tag>
                <el-tag class="contract-status" effect="dark" type="success" v-else-if=" scope.row.status==21">已生效
                </el-tag>
                <el-tag class="contract-status" effect="dark" type="error" v-else-if=" scope.row.status==22">待生效驳回
                </el-tag>
              </template>

            </el-table-column>
            <el-table-column
                prop="creator"
                label="创建者"
                min-width="15%">
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题"
                min-width="40%"

                show-overflow-tooltip>
              <template #default="scope">
                <el-link :href="'#/contract/view?id='+scope.row.id" target="_blank">{{ scope.row.title }}</el-link>
              </template>
            </el-table-column>
            <el-table-column
                prop="tag"
                label="标签"
                min-width="30%"
                show-overflow-tooltip>
              <template #default="scope">
                <div style="display: flex; line-height: 50px">
                  <div>
                    <el-button type="primary" @click="setContractApprovalProcessAndPermission(scope.row)" plain
                               v-if="scope.row.hasMetaApproval==0 && userInfo && userInfo.isManagerRole" style="margin-right: 20px">设置该上传合同的权限与流程
                    </el-button>
                    <el-button type="primary" @click="setContractApprovalProcessAndPermission(scope.row)" plain
                               v-if="(scope.row.hasMetaApproval==1 && userInfo && userInfo.isManagerRole)" style="margin-right: 20px">修改权限和审批流程
                    </el-button>
                  </div>
                  <div>
                    <el-tag effect="plain" type="info" v-for="(item,index) in scope.row.tags" :key="index"
                            v-show="item!=null" style="margin-right: 10px">{{ item }}
                    </el-tag>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="上次更新"
                width="200"
                show-overflow-tooltip>
              <template #default="scope">
                {{ new Date(scope.row.updateTime).toLocaleString() }}
              </template>
            </el-table-column>
          </el-table>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import {Search} from '@element-plus/icons'
import {getContracts} from "../api/api";
import {ElMessage} from "element-plus";
import ContractSideMenu from "../components/ContractSideMenu";


export default {
  name: "ContractList",
  components: {
    ContractSideMenu,
    Search,
  },
  mounted() {
    let filter = this.$route.query.filterType;
    if (filter) {
      this.Filter = filter;
    }

    getContracts(this.Filter,this.currentIndex-1,this.size).then(res => {
      if (res.status == 200) {
        if (res.data.code == 0) {
          this.contractList = res.data.data.content;
          this.totalCount = res.data.data.totalElements;
          this.totalPage = res.data.data.totalPages;
        } else {
          ElMessage.error(res.data.msg);
        }
      }
    }).catch(e => {
      console.error(e);
      ElMessage.error("请求失败");
    });
  },
  data() {
    return {
      searchData: '',
      contractList: [],
      Filter: 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      currentIndex:1,
      size:10,
      totalCount:0,
      totalPage:0,
    }
  },
  methods: {
    setContractApprovalProcessAndPermission: function (row) {
      this.$router.push({
        name: 'contractProcessPermission',
        query: {
          contractId: row.id
        }
      })
    },
    handleSelectionChange: function () {

    },
    deleteContract:function (){
      ElMessage.error("正在开发中....")
    },
    currentChange:function (newIndex){
      this.currentIndex = newIndex
      getContracts(this.Filter,this.currentIndex-1,this.size).then(res=>{
        if(res.status==200){
          this.contractList = res.data.data.content;
          this.totalCount = res.data.data.totalElements;
          this.totalPage = res.data.data.totalPages;
        }
      })
    },
    sizeChange:function (){

    }
  }
}
</script>

<style scoped>
.search-input input {
  padding-left: 50px !important;
}

.contract-status {
  height: 30px;
  width: 100px;
  border-radius: 15px;
  text-align: center;
}
</style>
